<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <div id="page">
    <!-- Header -->
    <Header :name="category" />
    <!--End Header -->

    <!-- Footer -->
    <Footer logged @reload="reloadPage" />
    <!--End Footer -->

    <div class="page-title-clear"></div>
    <!--Page Content -->
    <div class="page-content">
      <div class="card card-style">
        <div class="content">
          <!-- Level 2 Category -->
          <p
            v-if="parentName"
            @click="reloadPage"
            class="font-600 mb-n1 color-blue-light"
          >
            <i class="fa fa-arrow-left"></i>
            kembali
          </p>
          <!-- End Level 2 Category -->
          <h1 v-if="parentName" class="mb-3">{{ parentName }}</h1>
          <div v-if="parentName" class="divider mb-0"></div>
          <EmptyState
            v-if="categoryList.length === 0"
            :message1="'Maaf saat ini belum ada pekerjaan yang tersedia'"
            :cardCenter="false"
          />
          <div
            v-if="categoryList.length"
            class="list-group list-custom-small list-menu ms-0 me-2"
          >
            <!-- Level 2 Category / view all -->
            <a
              v-if="parentName"
              href="#"
              @click="
                getData({
                  id: parentId,
                  view_all: true,
                  level: 'L1',
                  name: parentName,
                })
              "
            >
              <i
                class="
                  fa
                  font-14
                  fa-bars
                  rounded-xl
                  shadow-xl
                  bg-blue-dark
                  color-white
                  bg-highlight
                "
              ></i>
              <span class="font-500">Tampilkan Semua</span>
              <i class="fa fa-angle-right"></i>
            </a>
            <!-- End Level 2 Category /  view all -->

            <Category
              v-for="category in categoryList"
              :key="category.id"
              :category="category"
              @getData="getData"
            />
          </div>
        </div>
      </div>
    </div>
    <!--End Page Content -->
  </div>
</template>

<script>
import axios from "axios";
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

import EmptyState from "@/components/EmptyState";
import Category from "@/components/Category";

export default {
  name: "CategoryList",
  components: {
    Header,
    Footer,
    Category,
    EmptyState,
  },
  data() {
    return {
      paramsId: "",
      dataSearch: "",
      categoryList: [],
      listJob: [],
      apiUrl: "/api/v1/employer/category/",
      isAuthenticated: this.$store.state.isAuthenticated,
      isLoading: true,
      parentName: "",
      viewAll: false,
    };
  },
  mounted() {
    init_template();
    this.getCategory();
  },
  methods: {
    menuClose,
    menuOpen,
    reloadPage() {
      this.viewAll = false;
      this.parentName = "";
      this.listJob = [];
      this.categoryList = [];
      this.showJob = false;
      this.parentId = "";
      this.getCategory();
    },
    filter() {
      this.getData({
        filter: "search",
        id: this.parentId,
        view_all: this.viewAll,
      });
    },
    getData(params) {
      /*get level 2 category*/
      if (params.level === "L1" && !("view_all" in params)) {
        this.getCategory(params);
      } else {
        /*get job by category level 2 or level 1*/
        this.$router.push({
          name: "CategoryJobList",
          params: {
            parent: params.id,
            level: params.level,
          },
        });
      }
    },
    async getCategory(params) {
      var url = "";

      /*get category level 2*/
      if (params) {
        url = this.apiUrl + "?category=" + params.id;
        this.parentName = params.name;
        this.parentId = params.id;
      } else {
        url = this.apiUrl;
      }

      await axios
        .get(url)
        .then((response) => {
          var responseData = response.data;

          this.categoryList = responseData;
          this.isLoading = false;
          if ("job_set" in responseData) {
            console.log("response Data Job Set :", response.data);
            responseData.forEach((item) => {
              item.job_set.forEach((item2) => {
                this.listJob.push(item2);
              });
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.bg-banner {
  background-image: url("~@/assets/images/banner/banner.svg");
}
</style>
