<template>
  <a
    @click="
      getData({ id: category.id, name: category.name, level: category.level })
    "
  >
    <i
      class="
        fa fa-th-large
        font-14
        rounded-sm
        shadow-xl
        bg-blue-dark
        color-white
        bg-highlight
      "
    ></i>
    <span class="font-500" style="overflow: hidden">{{ category.name }}</span>
    <i class="fa fa-angle-right"></i>
  </a>
</template>

<script>
export default {
  name: "Category",
  emits: ["getData"],
  props: {
    category: Object,
  },
  methods: {
    getData(params) {
      this.$emit("getData", params);
    },
  },
};
</script>
